
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IShipment } from "@/types/shipment";
import { IReceivedOrder } from "@/types/received_order";
import { IProductShipment } from "@/types/product_shipment";
import { ICustomer } from "@/types/customer";
import { IBranch } from "@/types/branch";

@Component({
  components: {}
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //shipment
  @Action("shipment/userGet")
  public getshipment!: (id: number) => Promise<boolean>;

  @Getter("shipment/single")
  public shipment!: IShipment;

  @Mutation("shipment/clear")
  public clearShipment!: () => void;

  // received_order
  @Action("received_order/userGet")
  public getReceivedOrder!: (received_order_id: number) => Promise<boolean>;

  @Getter("received_order/single")
  public received_order!: IReceivedOrder;

  @Mutation("received_order/clear")
  public clearReceivedOrder!: () => void;

  //customer
  @Action("customer/userGet")
  public getCustomer!: (customer_id: number) => Promise<boolean>;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/userGet")
  public getBranch!: (branch_id: number) => Promise<boolean>;

  @Getter("branch/single")
  public branch!: IBranch;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  // fields
  public shipment_id = 0;

  public headers = [
    { text: "商品名", value: "product_name", sortable: false },
    { text: "品番", value: "product_code", sortable: false },
    { text: "顧客品番", value: "customer_pn", sortable: false },
    { text: "ロットNo", value: "lot_no", sortable: false },
    { text: "備考", value: "memo", sortable: false },
    { text: "出荷数量", value: "quantity", sortable: false, align: "end" },
    {
      text: "仕入単価",
      value: "buying_unit_price",
      sortable: false,
      align: "end"
    },
    { text: "仕入単価合計", value: "amount", sortable: false, align: "end" },
    {
      text: "売単価",
      value: "selling_unit_price",
      sortable: false,
      align: "end"
    },
    {
      text: "売単価合計",
      value: "selling_unit_amount",
      sortable: false,
      align: "end"
    }
  ];

  public async created() {
    this.shipment_id = Number(this.$route.params.shipment_id);
    this.clearShipment();
    this.clearReceivedOrder();
    this.clearCustomer();
    this.clearBranch();

    await this.getshipment(this.shipment_id);
    await this.getReceivedOrder(this.shipment.received_order_id);
    await this.getCustomerInfomation();
  }

  // 受注者(顧客)情報をもってくる
  public async getCustomerInfomation() {
    await this.getCustomer(this.received_order.customer_id);
    if (this.received_order.branch_id) {
      await this.getBranch(this.received_order.branch_id);
    }
  }

  //出荷商品表示用配列生成
  get setValue() {
    return this.shipment.product_shipment.map(el => ({
      product_shipment_id: el.id,
      product_name: el.product_name,
      product_code: el.product_code,
      customer_pn: el.customer_pn,
      lot_no: el.lot_no,
      memo: el.memo,
      selling_unit_price: Number(el.selling_unit_price),
      amount: Number(el.amount),
      buying_unit_price: Number(el.buying_unit_price),
      selling_unit_amount: Number(el.selling_unit_amount),
      quantity: Number(el.quantity)
    }));
  }

  //売単価合計計算
  public amount_count(val: Array<IProductShipment>) {
    let amount_num = 0;
    val.forEach(element => {
      amount_num += Number(element.selling_unit_amount);
    });

    return amount_num;
  }
}
